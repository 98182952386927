import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import servicesData from '../../data/service-details.json';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ServicesDetailBody from './ServicesDetailsBody/ServicesDetailBody';
import ScrollToTop from "../../ScrollToTop";

function ServiceDetails() {
    const params = useParams();
    const [serviceDetails, setServiceDetails] = useState(null);

    useEffect(() => {
        console.log("params", params)
        const foundService = servicesData.find((service) => service.id === params.id);
        setServiceDetails(foundService);
        console.log(foundService);
    }, [params]);

    console.log(serviceDetails);

    return (
        <div>
            <ScrollToTop/>
            <Header />
            {serviceDetails ? <ServicesDetailBody serviceDetails={serviceDetails} /> : null}
            <Footer />
        </div>
    );
}

export default ServiceDetails;

