import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import servicesData from '../../../data/service-details.json';
import "./ServiceCardSmall.css";

const ServiceCardSmall = ({ serviceId }) => {

  const [serviceDetails, setServiceDetails] = useState({});
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    const foundService = servicesData.find((service) => service.id === serviceId);
    if (foundService)
    {
      setServiceDetails(foundService);
    }
    setisLoading(false)
    console.log("service card small", foundService);
  }, [serviceId])


  return (
    isLoading ? (
      <> isLoading</>
    ) : (
      <Link to={`/service-details/${serviceId}`} className="itr-midcard-link">
        <div className="itr-midcard">
          <div className="midcardimg-container">
            <img className="service-detail-img" src={serviceDetails.imageUrl} alt="itrcardbg" />
          </div>
          <div className="itr-midcard-data">
            <h2 className="itr-midcard-title">{serviceDetails.serviceName}</h2>
            <p className="itr-midcard-text">{serviceDetails.shortDesc}</p>
          </div>
        </div>
      </Link>
    ))
};

export default ServiceCardSmall;
