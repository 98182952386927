import React from "react";
import { NavLink } from "react-router-dom"
import { HashLink } from "react-router-hash-link";
import "./Footer.css";
import {SocialDock} from "../dock/social_dock";

const Footer = () => {


  // const handleClick = () => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };
  return (
    <div className="footer-section">
      <div className="footer-top">
        <div className="branding">
          <HashLink to="/" className="logo-link">
            <div className="logo">
              <img className="logo-image" src="/images/logo.svg" alt="logo" />
              <p>Easy Tax<br/>Consultancy </p>
            </div>
          </HashLink>
          <SocialDock/>

        </div>

        <div className="link-info">
          <ul className="link-list">
            <h3 className="link-head">Important Links</h3>
            <li>
              <HashLink to="/#popularServices" smooth={true} duration={300} className="link-text">
                Popular Services
              </HashLink>
            </li>
            {/*<li>*/}
            {/*  <HashLink to="/#about" smooth={true} duration={300} className="link-text">*/}
            {/*    About us*/}
            {/*  </HashLink>*/}
            {/*</li>*/}
            <li>
              <HashLink to="/#contactUs" smooth={true} duration={300} className="link-text">
                Contact Us
              </HashLink>
            </li>
          </ul>
        </div>

        <div className="service-info">
          <ul className="service-list">
            <h3 className="service-head">Our Services</h3>
            <li>
              <HashLink to="/category-details/tax-filing" className="link-text">
                Tax Filing
              </HashLink>
            </li>
            <li>
              <HashLink to="/category-details/registration" className="link-text">
                Registration Services
              </HashLink>
            </li>
            <li>
              <HashLink to="/category-details/mca" className="link-text">
                MCA
              </HashLink>
            </li>
            <li>
              <HashLink to="/category-details/miscellaneous" className="link-text" >
                Miscellaneous
              </HashLink>
            </li>
          </ul>
        </div>

        <div className="contact-info">
          <ul className="contact-list">
            <h3 className="contact-head">Contact info</h3>
            <li className="info-text">Janta Cinema Compound</li>
            <li className="info-text">Ward No. 15, Banakata</li>
            <li className="info-text">Gopalganj, Bihar 841428</li>
          </ul>
        </div>
      </div>

      <hr className="gap-line" />

      <div className="footer-bottom">
        <div className="policy-info">
          <li>
            <NavLink to="/privacy" className="policy-text">
              Privacy Policies
            </NavLink>
          </li>
          <li>
            <NavLink to="/terms" className="policy-text" >
              Terms and Conditions
            </NavLink>
          </li>
        </div>
        <div className="copyright-info">
          <li className="copyright-text">
            Copyright &#169;2024, All rights reserved
          </li>
        </div>
      </div>
    </div>
  );
};

export default Footer;