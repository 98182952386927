import "./App.css";
import {Route, Routes} from "react-router-dom";
import Home from "./Home.jsx";
import ServiceDetails from "./Components/ServiceDetails/ServiceDetails";
import CategoryDetails from "./Components/CategoryDetails/CategoryDetails";
import Privacy from "./Components/Footer/Privacy.js";
import TermsPage from "./Components/Footer/Terms.js";
import AllServices from "./Components/AllServices/AllServices";

const App = () => (

    <div className="App">
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/service-details/:id" element={<ServiceDetails/>}/>
            <Route path="/category-details/:id" element={<CategoryDetails/>}/>
            <Route exact path="/terms" element={<TermsPage/>}/>
            <Route exact path="/privacy" element={<Privacy/>}/>
            <Route exact path="/all-services" element={<AllServices/>}/>
            <Route path="*" element={<Home/>}/>
        </Routes>
    </div>

);

export default App;
