import React from 'react';
import './Statistics.css';
import {cn} from "../../lib/utils";
import { useInView, useMotionValue, useSpring } from "framer-motion";
import { useEffect, useRef } from "react";
import {HashLink} from "react-router-hash-link";

const NumberTicker = ({
                          value,
                          direction = "up",
                          delay = 0,
                          className,
                      }) => {
    const ref = useRef(null);
    const motionValue = useMotionValue(direction === "down" ? value : 0);
    const springValue = useSpring(motionValue, {
        damping: 60,
        stiffness: 100,
    });
    const isInView = useInView(ref, { once: true, margin: "0px" });

    useEffect(() => {
        if (isInView) {
            setTimeout(() => {
                motionValue.set(direction === "down" ? 0 : value);
            }, delay * 1000);
        }
    }, [motionValue, isInView, delay, value, direction]);

    useEffect(() => {
        return springValue.on("change", (latest) => {
            if (ref.current) {
                ref.current.textContent = Intl.NumberFormat("en-US").format(latest.toFixed(0));
            }
        });
    }, [springValue]);

    return (
        <span
            className={cn(
                "inline-block tabular-nums text-black",
                className,
            )}
            ref={ref}
        />
    );
};


const Statistics = () => {
    const stats = [
        { value: '5000+', label: 'TRUST EASYTAX' },
        { value: '30+', label: 'EMPLOYER FIRMS' },
        { value: '80Cr+', label: 'WORTH TAXES FILED' },
        { value: '500K+', label: 'PEOPLE-HOURS SAVED' },
    ];

    return (

        <div className="flex flex-col items-center mt-16 md:p-16 p-8">
            <h2 className="text-base font-bold">EasyTax Consultancy Facts</h2>
            <h2 className="text-4xl font-semibold mt-3">
                Reach out to our expert team today to schedule a <span className="text-orange-600">Free Consultation</span>
            </h2>
            <p className="text-base mt-5">
                We adhere to the highest professional standards of cConfidentiality and Ethics you can trust us
                to provide you with the best possible service.
            </p>
            <HashLink to="/#contactUs" smooth={true} duration={300}>
                <button className="inline-flex items-center my-5 text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-4 py-2 text-center">
                    Schedule Consultation

                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg>
                </button>
            </HashLink>
            <div className="statistics-container w-full">
                <div className="stat-item">
                    <div className="stat-value"><NumberTicker value={5000}/>+</div>
                    <div className="stat-label">TRUST EASYTAX</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value"><NumberTicker value={30}/>+</div>
                    <div className="stat-label">EMPLOYER FIRMS</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value"><NumberTicker value={80}/>Cr+</div>
                    <div className="stat-label">WORTH TAXES FILED</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value"><NumberTicker value={500}/>K+</div>
                    <div className="stat-label">PEOPLE-HOURS SAVED</div>
                </div>

            </div>

        </div>
    );
};

export default Statistics;
