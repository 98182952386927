import React, {useEffect} from "react";
import "./CategoryCard.css";
import { Link } from "react-router-dom";

const CategoryCard = ({ category , className}) => {

    useEffect(() => {
        console.log(`product-card ${className}`)
    }, []);
  return (
    <Link to={`/category-details/${category.id}`} style={{"textDecoration": "none"}}
          className={`${className} flex flex-col max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 `}>

        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{category.name}</h5>
        <p className="font-normal text-gray-700 grow">{category.shortDesc}</p>
        <a href={`/category-details/${category.id}`} className="inline-flex font-medium items-center text-blue-600 hover:underline">
            Learn more
            <svg className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
            </svg>
        </a>
    </Link>
  );
};

export default CategoryCard;
