import React, {useEffect, useRef, useState} from "react";
import "./Hero.css";
import {HashLink} from "react-router-hash-link";

import {cn} from "../../lib/utils";
import {AnimatePresence, motion, useAnimation, useInView} from "framer-motion";


const Hero = () => {
    return (

            <div className="hero-section bg-gradient-to-b from-blue-300 to-transparent">
                <div className="hero-container">
                    <div className="hero-left">
                        {/*<BoxReveal boxColor={"#102C57"} duration={0.5}>*/}
                        <h2 className="hero-left-text">
                            Get your <br/>
                            <span>Taxes done right,</span> <br/> Every time
                        </h2>
                        {/*</BoxReveal>*/}
                        <div className="hero-left-sub h-20">
                            <BoxReveal boxColor={"#102C57"} duration={0.5}>
                                <WordRotate  words={["REGISTRATIONS", "COMPLIANCE", "FILINGS"]}/>
                            </BoxReveal>
                        </div>
                        <BoxReveal boxColor={"#102C57"} duration={0.5}>
                            <h3 className="hero-left-para">Getting your business started with simple, swift and
                                reasonably
                                priced legal services, online.</h3>
                        </BoxReveal>
                        <BoxReveal boxColor={"#102C57"} duration={0.5}>
                            <HashLink to="/#contactUs" smooth={true} duration={300}>
                                <button className="my-2 text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-4 py-2 text-center">
                                    Get started</button>
                            </HashLink>
                        </BoxReveal>
                    </div>
                    <div className="hero-right">
                        <img className="heroimg skew-y-6 " src="/images/heroimg.svg" alt="heroimg"/>
                    </div>
                </div>
            </div>

    );
};

const BoxReveal = ({
                       children,
                       width = "fit-content",
                       boxColor = "#5046e6",
                       duration = 0.5,
                   }) => {
    const mainControls = useAnimation();
    const slideControls = useAnimation();

    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    useEffect(() => {
        if (isInView) {
            slideControls.start("visible");
            mainControls.start("visible");
        } else {
            slideControls.start("hidden");
            mainControls.start("hidden");
        }
    }, [isInView, mainControls, slideControls]);

    return (
        <div ref={ref} style={{position: "relative", width, overflow: "hidden"}}>
            <motion.div
                variants={{
                    hidden: {opacity: 0, y: 75},
                    visible: {opacity: 1, y: 0},
                }}
                initial="hidden"
                animate={mainControls}
                transition={{duration: duration, delay: 0.25}}
            >
                {children}
            </motion.div>

            <motion.div
                variants={{
                    hidden: {left: 0},
                    visible: {left: "100%"},
                }}
                initial="hidden"
                animate={slideControls}
                transition={{duration: duration, ease: "easeIn"}}
                style={{
                    position: "absolute",
                    top: 4,
                    bottom: 4,
                    left: 0,
                    right: 0,
                    zIndex: 20,
                    background: boxColor,
                }}
            />
        </div>
    );
};


function WordRotate({
                        words,
                        duration = 2500,
                        framerProps = {
                            initial: {opacity: 0, y: -50},
                            animate: {opacity: 1, y: 0},
                            exit: {opacity: 0, y: 50},
                            transition: {duration: 0.25, ease: "easeOut"},
                        },
                        className,
                    }) {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, duration);

        // Clean up interval on unmount
        return () => clearInterval(interval);
    }, [words, duration]);

    return (
        <div className="overflow-hidden py-2">
            <AnimatePresence mode="wait">
                <motion.h1
                    key={words[index]}
                    className={cn(className)}
                    {...framerProps}
                >
                    {words[index]}
                </motion.h1>
            </AnimatePresence>
        </div>
    );
}


export default Hero;
