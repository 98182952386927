import React from 'react'
import "./ProductqaCard.css"
import {Link} from 'react-router-dom'

const QuickAccessProductCard = (props) => {
    return (
        <Link to={`/service-details/${props.id}`}>
            <div className="group flex flex-col max-w-sm h-full p-6 bg-white border border-gray-200 rounded-lg
             shadow hover:bg-blue-300 hover:border-blue-950 hover:text-white">

                <img className="w-7 h-7 text-gray-500  mb-3" src={props.image} alt="{props.image}"/>
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{props.heading}</h5>
                <p className="mb-3 font-normal text-gray-700 grow">{props.para}</p>
                <Link to={`/service-details/${props.id}`}
                      className="inline-flex items-center font-medium text-blue-600
                       group-hover:font-bold group-hover:text-blue-800">
                    Learn more
                    <svg className=" w-2.5 h-2.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                    </svg>
                </Link>
            </div>
        </Link>

    )
}

export default QuickAccessProductCard