import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBFhedGxhaAWvxkoBZ-UjT-NBy8S_5jXyw",
    authDomain: "easytaxconsulting-project.firebaseapp.com",
    databaseURL: "https://easytaxconsulting-project-default-rtdb.firebaseio.com",
    projectId: "easytaxconsulting-project",
    storageBucket: "easytaxconsulting-project.appspot.com",
    messagingSenderId: "143448792148",
    appId: "1:143448792148:web:e45b38e3b860ecee5761c1",
    measurementId: "G-0Z6XH2P3ED"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);