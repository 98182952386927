import React from "react";
import "./Terms.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const TermsPage = () => {
  return (

  <div id="terms">
      <Header />
      <div className="terms-container">
        <p>Terms and Conditions</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to Find Flames! These terms and conditions outline the rules
          and regulations for the use of our Service, located at
          www.findflames.com.
        </p>

        <h2>2. Acceptance of Terms</h2>
        <p>
          By accessing or using the Service, you agree to be bound by these
          terms and conditions. If you disagree with any part of these terms and
          conditions, you must not use our Service.
        </p>

        <h2>3. Privacy Policy</h2>
        <p>
          Our Privacy Policy describes how we collect, use, and store your
          personal information. By using our Service, you consent to the
          collection and use of your information as described in our Privacy
          Policy.
        </p>

        <h2>4. Content</h2>
        <p>
          Our Service allows you to post, link, store, share, and otherwise make
          available certain information, text, graphics, videos, or other
          material ("Content"). You are responsible for the Content you post on
          the Service, and you agree not to post any Content that violates these
          terms and conditions.
        </p>

        <h2>5. Prohibited Uses</h2>
        <p>
          You may use our Service only for lawful purposes and in accordance
          with these terms and conditions. You agree not to use the Service:
        </p>
        <ul>
          <li>
            5.1. In any way that violates any applicable local, national, or
            international law or regulation.
          </li>
          <li>
            5.2. For the purpose of exploiting, harming, or attempting to
            exploit or harm minors in any way by exposing them to inappropriate
            content or otherwise.
          </li>
          <li>
            5.3. To transmit, or procure the sending of, any advertising or
            promotional material, including any "junk mail", "chain letter,"
            "spam," or any other similar solicitation.
          </li>
          <li>
            5.4. To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity.
          </li>
          <li>
            5.5. In any way that infringes upon the rights of others, or in any
            way is illegal, threatening, fraudulent, or harmful, or in
            connection with any unlawful, illegal, fraudulent, or harmful
            purpose or activity.
          </li>
          <li>
            5.6. To engage in any other conduct that restricts or inhibits
            anyone's use or enjoyment of the Service, or which, as determined by
            us, may harm or offend the Company or users of the Service, or
            expose them to liability.
          </li>
        </ul>

        <h2>6. Accounts</h2>
        <p>
          When you create an account with us, you guarantee that you are above
          the age of 18 and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on the Service.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </p>

        <h2>7. Termination</h2>
        <p>
          We may terminate or suspend your account and bar access to the Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the terms and conditions.
        </p>

        <h2>8. Governing Law</h2>
        <p>
          These terms and conditions shall be governed and construed in
          accordance with the laws of your country, without regard to its
          conflict of law provisions. Our failure to enforce any right or
          provision of these terms will not be considered a waiver of those
          rights. If any provision of these terms is held to be invalid or
          unenforceable by a court, the remaining provisions of these terms will
          remain in effect. These terms constitute the entire agreement between
          us regarding our Service and supersede and replace any prior
          agreements we might have had between us regarding the Service.
        </p>

        <h2>9. Changes to These Terms and Conditions</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these terms at any time. If a revision is material, we will try to
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>

        <h2>10. Contact Us</h2>
        <p>
          If you have any questions about these terms and conditions, please
          contact us at <b>info@findflames.com</b>.
        </p>

        <h2>11. Intellectual Property Rights</h2>
        <p>
          The Service and its original content, features, and functionality are
          and will remain the exclusive property of Find Flames and its
          licensors. The Service is protected by copyright, trademark, and other
          laws of both the country and foreign countries. Our trademarks and
          trade dress may not be used in connection with any product or service
          without the prior written consent of Find Flames.
        </p>

        <h2>12. Disclaimer</h2>
        <p>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement, or course of performance.
        </p>

        <h2>13. Limitation of Liability</h2>
        <p>
          In no event shall Find Flames, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential, or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Service; (ii) any conduct or content of
          any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use, or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence), or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </p>

        <h2>14. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Find Flames and its
          affiliates, directors, officers, employees, and agents from and
          against any and all claims, damages, obligations, losses, liabilities,
          costs, or debt, and expenses (including but not limited to attorney's
          fees) arising from: (i) your use of and access to the Service; (ii)
          your violation of any term of these terms and conditions; (iii) your
          violation of any third-party right, including without limitation any
          copyright, property, or privacy right; or (iv) any claim that your
          Content caused damage to a third party. This defense and
          indemnification obligation will survive these terms and your use of
          the Service.
        </p>

        <h2>15. Severability</h2>
        <p>
          If any provision of these terms is found to be unenforceable or
          invalid under any applicable law, such unenforceability or invalidity
          will not render these terms unenforceable or invalid as a whole, and
          such provisions will be deleted without affecting the remaining
          provisions herein.
        </p>

        <h2>16. Assignment</h2>
        <p>
          We may assign, transfer, or otherwise dispose of our rights and
          obligations under these terms, in whole or in part, at any time
          without notice. You may not assign these terms or transfer any rights
          or obligations under these terms without our prior written consent.
        </p>

        <h2>17. Waiver</h2>
        <p>
          Our failure to assert any right or provision under these terms shall
          not constitute a waiver of such right or provision. No waiver of any
          term of these terms shall be deemed a further or continuing waiver of
          such term or any other term.
        </p>

        <h2>18. Changes to the Service</h2>
        <p>
          We reserve the right to modify, suspend, or discontinue the Service at
          any time without notice. We will not be liable to you or any third
          party for any modification, suspension, or discontinuance of the
          Service.
        </p>

        <h2>19. Entire Agreement</h2>
        <p>
          These terms constitute the entire agreement between you and Find
          Flames regarding the use of the Service and supersede all prior and
          contemporaneous agreements, proposals, or representations, whether
          oral or written.
        </p>

        <h2>20. Effective Date</h2>
        <p>
          These terms and conditions are effective as of the date of your first
          use of the Service.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsPage;
