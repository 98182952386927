import React from "react";
import Hero from "./Components/Hero/Hero";
import {CategoriesSection, PopularServicesSection} from "./Components/Product/CategoriesSection";
import Footer from "./Components/Footer/Footer";
import Security from "./Components/Security/Security";
import ContactForm from "./Components/ContactForm/ContactForm";
import About from "./Components/AboutUs/About";
import TestimonialsNew from "./Components/Testimonials/Testimonials2";
import {Helmet} from 'react-helmet';
import Statistics from "./Components/Statistics/Statistics";
import Header from "./Components/Header/Header";
import FAQSection from "./Components/FAQSection";
import MapSection from "./Components/MapSection";

const SinglePage = () => {
    const canonicalUrl = 'https://easytaxconsultancy.com/';
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={canonicalUrl}/>
                <meta name="title" content="Easy Tax Consultancy: Compliance Made Easy"/>
                <meta name="description"
                      content="Expert tax filing and MCA services for individuals and businesses. Maximize your financial potential today."/>
                <meta name="googlebot-news" content="noindex"/>
                <meta name="robots" content="index"/>
                <title>Easy Tax Consultancy - Compliance Made Easy</title>
            </Helmet>
            <Header/>
            <Hero/>
            <CategoriesSection/>
            <About/>
            <Statistics/>
            <PopularServicesSection/>
            <TestimonialsNew/>
            {/*<Security/>*/}
            <ContactForm/>
            <MapSection/>
            <FAQSection/>
            <Footer/>
        </div>
    );
};

export default SinglePage;
