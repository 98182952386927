import React from "react";
import "./Privacy.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Privacy = () => {
  return (
    <>
      <Header />
      <div className="privacy">
        <p>Privacy Policy</p>
        <p>Last updated: January 25, 2023</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use, and disclosure of Your information when You use the
          Service and informs You about Your privacy rights and how the law
          protects You.    
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the{" "}
          <a
            href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Free Privacy Policy Generator
          </a>
        </p>
        <section>
          <h2>Interpretation and Definitions</h2>
          <article>
            <h3>Interpretation</h3>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or plural.
            </p>
          </article>
          <article>
            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <strong>Account</strong>: a unique account created for You to
                access our Service or parts of our Service.
              </li>
              <li>
                <strong>Affiliate</strong>: an entity that controls, is
                controlled by, or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest, or other securities entitled to vote for election of
                directors or other managing authority.
              </li>
              <li>
                <strong>Application</strong>: refers to Find Flames, the
                software program provided by the Company.
              </li>
              <li>
                <strong>Company</strong>: refers to Find Flames Pvt. Ltd, New
                Delhi, India.
              </li>
              <li>
                <strong>Country</strong>: refers to Delhi, India.
              </li>
              <li>
                <strong>Device</strong>: any device that can access the Service
                such as a computer, a cellphone, or a digital tablet.
              </li>
              <li>
                <strong>Personal Data</strong>: any information that relates to
                an identified or identifiable individual.
              </li>
              <li>
                <strong>Service</strong>: refers to the Application.
              </li>
              <li>
                <strong>Service Provider</strong>: any natural or legal person
                who processes the data on behalf of the Company. It refers to
                third-party companies or individuals employed by the Company to
                facilitate the Service, provide the Service on behalf of the
                Company, perform services related to the Service, or assist the
                Company in analyzing how the Service is used.
              </li>
              <li>
                <strong>Third-party Social Media Service</strong>: any website
                or social network website through which a User can log in or
                create an account to use the Service.
              </li>
              <li>
                <strong>Usage Data</strong>: refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </li>
              <li>
                <strong>User</strong>: the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>
          </article>
        </section>
        <section>
          <h2>Collection and Use of Personal Data</h2>
          <article>
            <h3>Types of Data Collected</h3>
            <p>
              While using Our Service, We may collect certain personal data that
              can be used to contact or identify You. Personal data may include,
              but is not limited to:
            </p>
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Usage data</li>
            </ul>
          </article>
          <article>
            <h3>Usage Data</h3>
            <p>
              Usage Data is collected automatically when using the Service. It
              may include information such as Your Device's Internet Protocol
              address (e.g., IP address), browser type, browser version, the
              pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers,
              and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers, and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
          </article>
        </section>
        <section>
          <h2>Disclosure of Personal Data</h2>
          <p>We may disclose Personal Data for the following purposes:</p>
          <ul>
            <li>
              <strong>Business Transactions:</strong> If the Company is involved
              in a merger, acquisition, or asset sale, Personal Data may be
              transferred. We will provide notice before Your Personal Data is
              transferred and becomes subject to a different Privacy Policy.
            </li>
            <li>
              <strong>Law enforcement:</strong> Under certain circumstances, the
              Company may be required to disclose Your Personal Data if required
              to do so by law or in response to valid requests by public
              authorities (e.g., a court or a government agency).
            </li>
            <li>
              <strong>Other legal requirements:</strong> The Company may
              disclose Your Personal Data in the good faith belief that such
              action is necessary to:
              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>
            </li>
          </ul>
        </section>
        <section>
          <h2>Security of Personal Data</h2>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
        </section>
        <section>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul>
            <li>By email: privacy@findflames.com</li>
            <li>
              By visiting this page on our website:{" "}
              <a
                href="https://www.findflames.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.findflames.com/contact
              </a>
            </li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
