import React, {useState} from "react";



const FAQSection = () => {

    const [accordions, setAccordion] = useState([
        {
            key: 1,
            title: 'What are the different types of taxes in India?',
            data: `The different types of taxes in India include:
                Direct Taxes (e.g., Income Tax, Corporate Tax)
                Indirect Taxes (e.g., Goods and Services Tax (GST), Customs Duty, Excise Duty)
                `,
            isOpen: false
        },
        {
            key: 2,
            title: 'How can I file my Income Tax Return (ITR) online?',
            data: `You can file your ITR online through the Income Tax Department’s e-filing portal.
             You need to register on the portal, log in, select the appropriate ITR form,
              fill in the required details, and submit the form or contact us and skip the hassle.`,
            isOpen: false
        },
        {
            key: 3,
            title: 'What is the process for incorporating a company in India?',
            data: `The process for incorporating a company in India includes:

                Obtaining Digital Signature Certificates (DSC) for proposed directors.
                Applying for Director Identification Number (DIN).
                Name reservation using the RUN (Reserve Unique Name) service.
                Filing the incorporation form (SPICe) along with MOA (Memorandum of Association) and AOA (Articles of Association).
                Payment of fees and stamp duty.
                Issuance of Certificate of Incorporation by the Registrar of Companies (ROC).`,
            isOpen: false
        },
        {
            key: 4,
            title: 'What is DIN and why is it required?',
            data: `DIN (Director Identification Number) is a unique identification number allotted to
             individuals who wish to become directors of a company.
             It is mandatory for anyone intending to be a director of a company in India.`,
            isOpen: false
        },
        {
            key: 5,
            title: 'How can I check the status of my company’s compliance with MCA?',
            data: `You can check the status of your company’s compliance with MCA by visiting the MCA
             portal and using the ‘View Public Documents’ or ‘Company/LLP Master Data’ services.`,
            isOpen: false
        },
        {
            key: 6,
            title: 'What are the annual compliance requirements for a private limited company in India?',
            data: `The annual compliance requirements for a private limited company include:

            Filing of Annual Return (Form MGT-7)
            Filing of Financial Statements (Form AOC-4)
            Holding Annual General Meeting (AGM)
            Maintaining statutory registers and records`,
            isOpen: false
        },
        {
            key: 7,
            title: 'How can I register a sole proprietorship in India?',
            data: `To register a sole proprietorship, you need to:

                Obtain a PAN card
                Register with the local Municipal Corporation under the Shops and Establishment Act
                Open a current bank account in the name of the business
                Obtain any other specific licenses or registrations required for your business`,
            isOpen: false
        },
        {
            key: 8,
            title: 'What is an LLP and how can it be registered?',
            data: `To register a sole proprietorship, you need to:

                 LLP (Limited Liability Partnership) is a hybrid business structure that combines the benefits of a partnership and a company. To register an LLP:

                Obtain DSC for designated partners.
                Apply for DIN.
                Reserve the LLP name using the RUN-LLP service.
                File incorporation form (FiLLiP) with the ROC.
                Execute LLP Agreement and file Form 3 with the ROC.`,
            isOpen: false
        },
        {
            key: 9,
            title: 'What is the procedure for obtaining GST registration?',
            data: `The procedure for obtaining GST registration involves:

                Submitting an online application on the GST portal.
                Providing necessary details and documents (PAN, Aadhaar, proof of business registration, address proof, bank account details, etc.).
                Verification of the application by GST officials.
                Issuance of GSTIN (Goods and Services Tax Identification Number) upon approval.
                `,
            isOpen: false
        },
    ]);

    const toggleAccordion = (accordionkey) => {
        const updatedAccordions = accordions.map((accord) => {
            if (accord.key === accordionkey) {
                return {...accord, isOpen: !accord.isOpen};
            } else {
                return {...accord, isOpen: false};
            }
        });

        setAccordion(updatedAccordions);
    };


    return (
        <section className="flex flex-col items-center p-8 md:p-24">
            <h2 className="text-base font-bold">FAQs</h2>
            <h2 className="text-4xl font-semibold mt-3">
                FAQs on Tax and Financial Services
            </h2>
            <div className="w-full py-16">
                {accordions.map((accordion) => (
                    <Accordion
                        key={accordion.key}
                        title={accordion.title}
                        data={accordion.data}
                        isOpen={accordion.isOpen}
                        toggleAccordion={() => toggleAccordion(accordion.key)}
                    />
                ))}
            </div>
        </section>
    )
}

function Accordion(props) {
    return (
        <div id="accordion-flush" data-accordion="collapse" onClick={props.toggleAccordion}
             data-active-classes="bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
             data-inactive-classes="text-gray-500 dark:text-gray-400">
            <h2 id="accordion-flush-heading-1">
                <button type="button"
                        className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
                        data-accordion-target="#accordion-flush-body-1" aria-expanded="true"
                        aria-controls="accordion-flush-body-1">
                    <span>{props.title}</span>
                    <svg className={`w-3 h-3 shrink-0 ${props.isOpen ? '': "rotate-180"}`} aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                              strokeWidth="2" d="M9 5 5 1 1 5"/>
                    </svg>
                </button>
            </h2>
            <div id="accordion-flush-body-1" className={props.isOpen ? "" : "hidden"} aria-labelledby="accordion-flush-heading-1">
                <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                        {props.data}
                    </p>

                </div>
            </div>
        </div>


    );
}
export default FAQSection