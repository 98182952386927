import React from "react";
import "./CategoryDetailsBody.css";
import ServiceCardSmall from "../ServiceCardSmall/ServiceCardSmall";
import PopularServiceList from "./PopularService/PopularServiceList";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const CategoryDetailsBody = ({ categoryDetail }) => {

    return (
        <div className="itr-section">
            <div className="itr-container">
                <div className="itr-left">
                    <h2 className="itr-left-head">{categoryDetail.name}</h2>
                    <p className="itr-left-para">{categoryDetail.desc}</p>
                    <HashLink to={`/home?Subject=${categoryDetail.name}#contactUs`}>
                        <button className="itr-btn">Contact us</button>
                    </HashLink>
                    {/* <Link to={{ pathname: '/', search: `?Subject=${categoryDetail.name}` }}>
                        <button className="itr-btn">Contact us</button>
                    </Link> */}
                </div>
                <div className="itr-right">
                    <img className="itrimg" src="/images/itr-filing.svg" alt="itrimg" />
                </div>
            </div>

            <div className="itr-middle">
                <h2 className="itr-middle-head">Services</h2>
                <div className="itr-midcard-wrapper">
                    {categoryDetail.services.map((serviceId, index) => (
                        <ServiceCardSmall key={index} serviceId={serviceId} />
                    ))}
                </div>
            </div>

            <h2 className="itr-last-head">Popular Services</h2>
            <div className="itr-last">
                <PopularServiceList />
            </div>
        </div >
    );
};

export default CategoryDetailsBody;
