import React from "react";
import {HashLink} from "react-router-hash-link";

const About = () => {
  return (
    <div id="AboutUs" className="flex flex-col md:flex-row md:p-24 lg:p-32 md:gap-32 p-8 gap-16 bg-[#8EC5FC]"

  style={{backgroundImage: "linear-gradient(62deg, #8EC5FC 0%, #fcd9c3 100%)"}}
    >
        <div className="flex-1 ">
          <img className="md:h-96 w-full rounded-lg top-0 left-0 "
              src="/images/aboutUsImage.jpg" alt="Aboutimage" />
        {/*<div className="h-56 w-64 absolute top-0 left-0 mt-64 ml-80 border-8 border-white">*/}
        {/*  <img className="rounded-lg h-48"*/}
        {/*       src="/images/aboutUsImage.jpg" alt="Aboutimage" />*/}

        {/*</div>*/}

        </div>
        <div className="flex-1">
          <h2 className="text-base font-bold">About Us</h2>
          <h2 className="text-4xl font-semibold mt-3">
            Experience the Difference with Our <span className="text-orange-600">Experts</span>
          </h2>
          <p className="text-base mt-5">
            At Easy Tax Consultancy, we are dedicated to simplifying the tax
            process for individuals and businesses alike. With our team of
            knowledgeable tax professionals, we provide expert guidance and
            support to navigate the complexities of taxation.
            <div className="mt-5"> Our mission is to empower you with the knowledge and resources needed to make
            informed financial decisions and optimize your tax outcomes.
            </div>
          </p>
          <HashLink to="/#contactUs" smooth={true} duration={300}>
            <button className="inline-flex items-center my-5 text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-4 py-2 text-center">
              Get Expert Guidance

              <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
            </button>
          </HashLink>
        </div>

    </div>
  );
};

export default About;
