import React, {useEffect, useState} from "react";
import "./Header.css";
import {HashLink} from "react-router-hash-link";

const Header = () => {
    const phoneLink = "tel:+916200545517"

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <div className="header bg-blue-300">
            <nav className="max-w-screen-x flex flex-wrap justify-between items-center mx-auto p-4">
                <HashLink to="/">
                    <div className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img className="h-10" src="/images/logo.svg" alt="Easy Tax Consultancy Logo"/>
                        <div className="self-center ">
                            <span
                                className="text-xl leading-tight font-semibold whitespace-nowrap ">EasyTax</span>
                            <div className="text-xs font-semibold leading-tight">Consultancy</div>
                        </div>

                    </div>
                </HashLink>
                <div className="flex md:order-2 space-x-2 md:space-x-0 rtl:space-x-reverse">
                    <button type="button"
                            className="text-white bg-blue-700  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg md:px-4 md:py-2 px-1.5 text-center ">
                        <a href={phoneLink} className="navmainlink text-white">
                            <div className="flex items-center">
                                <img src="/images/call.svg" alt="phone-icon" className="md:size-5 size-2"/>
                                <div className="flex flex-col items-start px-1">
                                    <p className="text-[6px]  md:text-xs ml-1">Call Now</p>
                                    <p className="text-[8px] md:text-sm font-bold ">+91 6200545517</p>
                                </div>
                            </div>
                        </a>
                    </button>
                    <button data-collapse-toggle="navbar-sticky" type="button"
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                            aria-controls="navbar-sticky" aria-expanded="false"
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M1 1h15M1 7h15M1 13h15"/>
                        </svg>
                    </button>
                </div>

                <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${showMobileMenu ? "" : " hidden"}`} id="navbar-sticky">
                    <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 md:bg-transparent bg-gray-50 rounded-lg  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
                        <li>
                            <a href="/"
                               className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                               aria-current="page">Home</a>
                        </li>
                        <li>
                            <a href="/all-services"
                               className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">Services</a>
                        </li>
                        <li>
                            <HashLink
                                to="/#AboutUs"
                                smooth={true}
                                duration={300}
                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">About</HashLink>
                        </li>

                        <li>
                            <HashLink
                                to="/#contactUs"
                                smooth={true}
                                duration={300}

                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0">Contact</HashLink>
                        </li>
                    </ul>
                </div>

            </nav>

        </div>
    );
};

export default Header;
