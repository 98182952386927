import React from "react";
import CategoryCard from "./CategoryCard/CategoryCard";
import QuickAccessProductCard from "./ProductqaCard/ProductqaCard";
import QuickAccessProductsList from "../../data/quick_access.json";
import categories from "../../data/category_data.json";

export  const CategoriesSection = () => {
  return (
    <div className="flex flex-col md:flex-row mb-16 gap-4 px-8 md:px-16">
        {categories.map((category, index) => (
            <CategoryCard key={index} category={category} className="flex-1" />
        ))}
    </div>
  );
};

export const PopularServicesSection = () => {
    return (
        <div id="popularServices" className="flex flex-col items-center p-8 md:p-16 mt-16"
        style={{backgroundImage:"radial-gradient(circle, rgba(252,160,15,0.7077424719887955) 0%, rgba(84,169,255,0.8702074579831933) 100%)"}}>
            <h2 className="text-base font-bold">Services</h2>
            <h2 className="text-4xl font-semibold mt-3 flex flex-col items-center">
                Trusted Experts at <div className="text-orange-600">Your Service</div>
            </h2>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 mt-16 gap-4 ">
                {QuickAccessProductsList.map((card, index) => (
                    <QuickAccessProductCard
                        key={index}
                        id={card.id}
                        image={card.image}
                        heading={card.heading}
                        para={card.para}
                    />
                ))}
            </div>
        </div>
    )
}

