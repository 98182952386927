import React, { useEffect, useState } from 'react';
import './PopularServiceCard.css';
import popularServices from '../../../../data/popular_services.json';
import servicesData from '../../../../data/service-details.json';
import { Link } from 'react-router-dom';

const PopularServiceList = () => {
    return (
        <div className='popular-service'>
            {popularServices.map((serviceId, index) => (
                <PopularServiceCard serviceId={serviceId} key={index} />
            ))}
        </div>
    );
};

export default PopularServiceList;

const PopularServiceCard = ({ serviceId }) => {
    const [serviceDetails, setServiceDetails] = useState(null);

    useEffect(() => {
        const foundService = servicesData.find((service) => service.id === serviceId);
        setServiceDetails(foundService);
        console.log(foundService);
    }, [serviceId]);

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    };

    return serviceDetails ? (
        <div className='card'>
            <Link to={`/service-details/${serviceId}`} className='service-card-link' onClick={handleClick}>
                <div className='card-frame'>
                    <img src={serviceDetails.imageUrl} alt='' className='card-image' />
                    <div className='card-content'>
                        <h2 className='card-title'>{serviceDetails.serviceName}</h2>
                        <p className='card-description'>{serviceDetails.shortDesc}</p>
                    </div>
                </div>
            </Link>
        </div>
    ) : (
        <>loading</>
    );
};
