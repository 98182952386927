import {cn} from "../../lib/utils";
import Marquee from "../card/marquee";
import React from "react";

const reviews = [
    {
        name: "Arjun",
        username: "@arjun_india",
        body: "The tax filing process was so smooth and hassle-free. I highly recommend their services.",
        img: "https://doodleipsum.com/56/avatar-4?i=be176fd7d38de78c85dbfba873eb723a",
    },
    {
        name: "Priya",
        username: "@priya_finance",
        body: "Expert advice on tax planning helped me save a lot. Truly professional and reliable.",
        img: "https://doodleipsum.com/56/avatar-4?i=92c4910fd5f023b627294a0ea2e1558c",
    },
    {
        name: "Rahul",
        username: "@rahul_biz",
        body: "Their MCA-related services have streamlined our business operations. Exceptional support!",
        img: "https://doodleipsum.com/56/avatar-4?i=22bb231aed29fe94c5a09cf96c70fc51",
    },
    {
        name: "Sneha",
        username: "@sneha_tax",
        body: "Top-notch financial services! Their expertise in compliance is unmatched. Highly satisfied.",
        img: "https://doodleipsum.com/56/avatar-4?i=37c1e32e78cda971d92a19e30181882c",
    },
    {
        name: "Vikram",
        username: "@vikram_growth",
        body: "With their help, I’ve maximized my financial potential. Excellent and dedicated solutions.",
        img: "https://doodleipsum.com/56/avatar-4?i=663b0e7ca496557503e9d7976f903b0c",
    },
    {
        name: "Ananya",
        username: "@ananya_mca",
        body: "Their personalized approach to MCA processes has been a game-changer for our company.",
        img: "https://doodleipsum.com/56/avatar-4?i=6d3461e01db40e794bc00b15b1c9b298",
    }


];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
                        img,
                        name,
                        username,
                        body,
                    }) => {
    return (
        <figure
            className={cn(
                "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4",
                // light styles
                "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
                // dark styles
                "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]",
            )}
        >
            <div className="flex flex-row items-center gap-2">
                <img className="rounded-full" width="32" height="32" alt="" src={img} />
                <div className="flex flex-col">
                    <figcaption className="text-sm font-medium dark:text-white">
                        {name}
                    </figcaption>
                    <p className="text-xs font-medium dark:text-white/40">{username}</p>
                </div>
            </div>
            <blockquote className="mt-2 text-sm">{body}</blockquote>
        </figure>
    );
};

const TestimonialsNew = () => {
    return (
        <div className="flex flex-col items-center mt-24 mb-24">
            <div className="flex items-center flex-col p-8 md:p-34">
                <h2 className="text-base font-bold ">Testimonials</h2>
                <h2 className="text-4xl font-semibold mt-3">
                    Listen what some of our clients have to <span className="text-orange-600">Say</span>
                </h2>
            </div>


            <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background py-10 ">
                <Marquee pauseOnHover className="[--duration:20s]">
                    {firstRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                </Marquee>
                <Marquee reverse pauseOnHover className="[--duration:20s]">
                    {secondRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                </Marquee>
                <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
                <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
            </div>

        </div>

    );
};

export default TestimonialsNew;
