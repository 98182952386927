import React from 'react';
import {Link} from "react-router-dom";

function MapSection() {
    const iframeStyle = {
        border: 0, width: "100%"
    };
    return (
        <div className="flex flex-col md:flex-row md:p-24 lg:p-32 md:gap-8 p-8 gap-4">

            <div className="flex flex-col flex-1 w-full  items-center">
                <h2 className="text-base font-bold">About Us</h2>
                <h2 className="text-4xl font-semibold mt-3">
                    Meet us in <span className="text-orange-600">Office</span>
                </h2>



                {/*<Link to="https:maps.app.goo.gl/co31z3ipTGLkJZqFA" className="flex flex-row pt-8">*/}
                {/*    <img className="px-2" src="/images/location.svg" alt="location"/>*/}
                {/*    <p>Navjivan Mill Compound, Memon Market, Kalol, Gujarat 382721</p>*/}
                {/*</Link>*/}

                <Link to="https:maps.app.goo.gl/XaFU9m355bC1Msek6" className="flex flex-row pt-4">
                    <img className="px-2" src="/images/location.svg" alt="location"/>
                    <p>Janta Cinema Compound, Ward No. 15, Banakata, Gopalganj, Bihar 841428</p>
                </Link>

                <Link to="tel:+916200545517" className="flex flex-row pt-4">
                    <img className="px-2" src="/images/call_black.svg" alt="call"/>
                    <p>+91 6200545517</p>
                </Link>

                <Link to="mailto:easytaxfiles@gmail.com" className="flex flex-row pt-4">
                    <img className="px-2" src="/images/mail.svg" alt="mail "/>
                    <p>Easytaxfiles@gmail.com</p>
                </Link>


            </div>

            <div className="flex-1 w-full bg-red-300">
                {/*<iframe*/}
                {/*    title="Google Maps - Navjivan Mill Compound"*/}
                {/*    src="https:www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.0377095015897!2d72.5031658!3d23.2417148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c25e22c05a347%3A0x95c110140f7d63fb!2sCity%20Mall-2%2C%20Navjivan%20Mill%20Compound%2C%20Memon%20Market%2C%20Kalol%2C%20Gujarat%20382721!5e0!3m2!1sen!2sin!4v1714797640959!5m2!1sen!2sin"*/}
                {/*    height="400" style={iframeStyle} allowFullScreen="" loading="lazy"*/}
                {/*    referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.7289805235846!2d84.43645687574998!3d26.46446367691722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39931b70d53cd41b%3A0xab931f39f2b8a55f!2sSingh%20Prakash%20and%20Associates%20(Proprietor%3A%20C.A.%20Prakash%20Kumar%20Singh)!5e0!3m2!1sen!2sin!4v1722876502926!5m2!1sen!2sin"
                         height="400" style={iframeStyle} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>


        </div>
    );
}

export default MapSection;