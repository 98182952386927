import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {db} from "../../Firebase"
import {addDoc, collection} from 'firebase/firestore';
import {toast} from "react-toastify";
import emailjs from 'emailjs-com';

const ContactForm = () => {

    const location = useLocation();
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [errors, setErrors] = useState({
        name: "", mobile: "", email: "", subject: ""
    });

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        console.log('query', query);
        if (query.get('Subject')) {
            setSubject(query.get('Subject'));
        }
    }, [location])


    const userCollection = collection(db, 'feedback');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleMobileChange = (e) => {
        setMobile(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    function sendEmail() {
        emailjs.send('service_z1xz5dm',
            'template_vbyvxua',
            {
                'form_name': name,
                'form_email': email,
                'form_subject': subject,
                'form_message': description,
                'form_phone': mobile
            },
            'fs-I3d8EXobM4FSqG'
        ).then(() => {
            console.log("Email sent")
        }).catch((e) => {
            console.error("Error while sending email", e)
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasError = false;
        const updatedErrors = {};

        if (name.trim() === "") {
            updatedErrors.name = "Name is required";
            hasError = true;
        }

        if (mobile.trim() === "") {
            updatedErrors.mobile = "Mobile number is required";
            hasError = true;
        }

        if (subject.trim() === "") {
            updatedErrors.subject = "Subject is required";
            hasError = true;
        }

        if (hasError) {
            setErrors(updatedErrors);
        } else {
            setErrors({
                name: "", mobile: "", email: "", subject: ""
            });

            const timestamp = new Date();

            const docRef = await addDoc(userCollection, {
                name: name,
                mobile: mobile,
                email: email,
                subject: subject,
                description: description,
                timestamp: timestamp
            });
            toast('🙏 Thank You, Our Team will contact soon.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            sendEmail();

            console.log("Document written with ID: ", docRef.id);
            setName("");
            setMobile("");
            setEmail("");
            setSubject("");
            setDescription("");
        }
    };

    const containerStyle = {
        display: "flex", justifyContent: "center", alignItems: "center", height: "60vh", overflow: "hidden",
    };

    const iframeStyle = {
        border: 0, width: "100vw"
    };

    return (
        <section id="contactUs" className="flex flex-col items-center md:p-24 lg:p-32 p-8"
                 style={{backgroundImage: "linear-gradient(62deg, #fcd9c3 0%, #8EC5FC 100%)"}}>
            <h2 className="text-base font-bold">Contact Us</h2>
            <h2 className="text-4xl font-semibold mt-3">
                Experience the Difference with Our <span className="text-orange-600">Experts</span>
            </h2>

            <form action="#" className="space-y-8 md:px-48 w-full mt-16">
                <div className="flex flex-col md:flex-row gap-8">
                    <div className="flex-1">
                        <label form="name" className="block mb-2 text-sm font-bold text-gray-900">Your Name</label>
                        <input type="text" id="name"
                               className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                        rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                               placeholder="Rohit Kumar" required={true}
                        onChange={handleNameChange}
                        value={name}/>
                    </div>
                    <div className="flex-1">
                        <label form="phone" className="block mb-2 text-sm font-bold text-gray-900">Phone Number</label>
                        <input type="number" id="phone"
                               className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                        rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                               placeholder="+91 9898393839" required={true}
                        onChange={handleMobileChange}
                        value={mobile}/>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-8">
                    <div className="flex-1">
                        <label form="subject" className="block mb-2 text-sm font-medium text-gray-900">Subject</label>
                        <input type="text" id="subject"
                               className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border
                                        border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"

                               placeholder="Let us know how we can help you" required={true}
                        onChange={handleSubjectChange}
                        value={subject}/>
                    </div>
                    <div className="flex-1">
                        <label form="email" className="block mb-2 text-sm font-bold text-gray-900 ">Your email</label>
                        <input type="email" id="email"
                               className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                        rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                               placeholder="name@gmail.com" required={false}
                               onChange={handleEmailChange}
                               value={email}
                        />
                    </div>
                </div>
                <div className="sm:col-span-2">
                    <label form="message" className="block mb-2 text-sm font-medium text-gray-900">Your message</label>
                    <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900
                                 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500
                                  focus:border-primary-500" placeholder="Leave a comment..."
                    onChange={handleDescriptionChange}
                              value={description}
                    ></textarea>
                </div>
                <div className="flex items-center mb-4">
                    <input checked id="checkbox-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label htmlFor="checkbox-1" className="ms-2 text-sm font-medium text-gray-900">I agree to the <a href="/terms" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</label>
                </div>
                <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white
                             rounded-lg bg-blue-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none
                              focus:ring-primary-300"
                    onClick={handleSubmit}
                >Send message</button>
            </form>

        </section>);
};

// <div style={containerStyle}>
//     <iframe
//         title="Google Maps - Navjivan Mill Compound"
//         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.0377095015897!2d72.5031658!3d23.2417148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c25e22c05a347%3A0x95c110140f7d63fb!2sCity%20Mall-2%2C%20Navjivan%20Mill%20Compound%2C%20Memon%20Market%2C%20Kalol%2C%20Gujarat%20382721!5e0!3m2!1sen!2sin!4v1714797640959!5m2!1sen!2sin"
//         width="600" height="450" style={iframeStyle} allowFullScreen="" loading="lazy"
//         referrerPolicy="no-referrer-when-downgrade"></iframe>
//     <iframe
//         title="Google Maps - Janta Cinema Compound"
//         src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14286.91621714139!2d84.4390474!3d26.4644613!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39931b70d53cd41b%3A0xab931f39f2b8a55f!2sSingh%20Prakash%20and%20Associates%20(Proprietor%3A%20CA%20Prakash%20Kumar%20Singh)!5e0!3m2!1sen!2sin!4v1686977228951!5m2!1sen!2sin"
//         width="600" height="450" style={iframeStyle} allowFullScreen="" loading="lazy"
//         referrerPolicy="no-referrer-when-downgrade">
//
//     </iframe>
//
//
// </div>

//
// <div className="formArea">
//     <div className="formRow">
//         <div className="formField">
//             <label htmlFor="name">Name<span className="error">*</span></label>
//             <input
//                 type="text"
//                 id="name"
//                 placeholder="Enter Your Name"
//                 value={name}
//                 onChange={handleNameChange}
//             />
//             {errors.name && <span className="error">{errors.name}</span>}
//         </div>
//         <div className="formField">
//             <label htmlFor="mobile">Mobile Number<span className="error">*</span></label>
//             <div className="mobileInputGroup">
//                 <input id="countryPin" className="countryPinInput"
//                        value="+ 91" readOnly/>
//                 <input
//                     type="number"
//                     id="mobileNumber"
//                     className="mobileNumberInput"
//                     placeholder="Enter mobile number"
//                     value={mobile}
//                     onChange={handleMobileChange}
//                 />
//             </div>
//             {errors.mobile && <span className="error">{errors.mobile}</span>}
//         </div>
//     </div>
//     <div className="formRow">
//         <div className="formField">
//             <label htmlFor="email">Email ID<span className="error"></span></label>
//             <input
//                 type="email"
//                 id="email"
//                 placeholder="Enter your Email ID (Optional)"
//                 value={email}
//                 onChange={handleEmailChange}
//             />
//         </div>
//         <div className="formField">
//             <label htmlFor="subject">Subject<span className="error">*</span></label>
//             <input
//                 type="text"
//                 id="subject"
//                 autoComplete="off"
//                 placeholder="Enter subject"
//                 value={subject}
//                 onChange={handleSubjectChange}
//             />
//             {errors.subject && <span className="error">{errors.subject}</span>}
//         </div>
//     </div>
//     <div className="formRow">
//         <div className="formField">
//             <label htmlFor="description">
//                 Describe your requirements (optional):
//             </label>
//             <textarea
//                 id="description"
//                 rows="5"
//                 placeholder="Describe your requirement"
//                 value={description}
//                 onChange={handleDescriptionChange}
//             ></textarea>
//         </div>
//     </div>
//     <div className="formRow">
//         <button
//             type="submit"
//             className="submitButton"
//             onClick={handleSubmit}
//         >
//             Submit
//         </button>
//     </div>
// </div>
// <div className="contactArea">
//     <p>Contact Info</p>
//     <Link to="https://maps.app.goo.gl/co31z3ipTGLkJZqFA" className="navmainlink">
//         <p>
//                             <span role="img" aria-label="Location">
//                                 <img src={LocationImage} alt="location"/>
//                             </span>
//             Navjivan Mill Compound, Memon Market, Kalol, Gujarat 382721
//         </p>
//     </Link>
//     <Link to="https://maps.app.goo.gl/XaFU9m355bC1Msek6" className="navmainlink">
//         <p>
//                             <span role="img" aria-label="Location">
//                                 <img src={LocationImage} alt="location"/>
//                             </span>
//             Janta Cinema Compound, Ward No. 15, Banakata, Gopalganj, Bihar 841428
//         </p>
//     </Link>
//     <Link to="tel:+916200545517" className="navmainlink">
//         <p>
//                             <span role="img" aria-label="Mobile">
//                                 <img src={call} alt="call"/>
//                             </span>{" "}
//             +91 6200545517
//         </p>
//     </Link>
//     <Link to="mailto:easytaxfiles@gmail.com" className="navmainlink">
//         <p>
//                             <span role="img" aria-label="Email">
//                                 <img src={mail} alt="mail "/>
//                             </span>
//             Easytaxfiles@gmail.com
//         </p>
//     </Link>
//
// </div>

export default ContactForm;