import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ScrollToTop from "../../ScrollToTop";
import ServicesList from "../../data/service-details.json";
import Categories from "../../data/category_data.json";
import {Link} from "react-router-dom";

function AllServices() {

    return (
        <div>
            <ScrollToTop/>
            <Header/>
            <div className="flex flex-col items-center p-8 md:p-12">
                <h2 className="text-4xl font-semibold mt-3 ">
                    Trusted Experts at <span className="text-orange-600">Your Service</span>
                </h2>
                {
                    Categories.map((category, categoryIndex) => (
                        <div key={categoryIndex} className="my-8">
                            <h2 className="text-4xl font-semibold">{category.name}</h2>
                            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 my-8 gap-4 ">
                                {category.services.map((service, serviceIndex) => {
                                    const details = ServicesList.find(obj => obj.id === service)
                                    return (
                                        <ServiceCard
                                            key={serviceIndex}
                                            id={service}
                                            image={details.imageUrl}
                                            heading={details.serviceName}
                                            para={details.shortDesc}
                                        />
                                    );
                                })}
                            </div>
                        </div>

                    ))
                }


            </div>

            <Footer/>
        </div>
    );
}


const ServiceCard = (props) => {
    return (
        <Link to={`/service-details/${props.id}`}>

               <div className="group flex flex-col max-w-sm h-full p-6 bg-white border border-gray-200 rounded-lg
             shadow hover:bg-blue-300 hover:border-blue-950">

                   <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{props.heading}</h5>
                   <p className="mb-3 font-normal text-gray-700 grow h-32 overflow-hidden">{props.para}</p>
                   <Link to={`/service-details/${props.id}`}
                         className="inline-flex items-center font-medium text-blue-600
                       group-hover:font-bold group-hover:text-blue-800">
                       Learn more
                       <svg className=" w-2.5 h-2.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                           <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                       </svg>
                   </Link>
               </div>
        </Link>

    )
}

export default AllServices;
