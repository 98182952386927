import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CategoryDetailsBody from "./Body/CategoryDetailsBody";
import { useParams } from "react-router-dom";
import categories from "../../data/category_data.json";
import ScrollToTop from "../../ScrollToTop";
import {Helmet} from "react-helmet";

function CategoryDetails() {
  const params = useParams();
  const [categoryDetails, setCategoryDetails] = useState({
      id: "tax-filing",
      name: "Tax Filing",
      shortDesc: "ITR Filing, GST Filing, TDS Filing, ESI Filing, PF Filing",
      desc: "At our finance consultancy firm, we take pride in offering an exceptional tax filing service that ensures our clients experience a seamless and stress-free tax season.\n\nOur team of highly skilled and experienced professionals understands the complexities of tax regulations and stays up to date with the latest changes, enabling us to provide accurate and comprehensive tax solutions.\n\n Whether you are an individual, a small business, or a large corporation, our tailored approach guarantees personalized attention and maximized deductions, while ensuring compliance with all legal requirements.",
      imageUrl: "/images/services/tax.jpg",
      services: [

      ]
  });

  useEffect(() => {
    console.log("Looking for category with id", params.id);
      const data = categories.find((category) => category.id === params.id);
      setCategoryDetails(data);
  }, [params]);

  return (

    <div>
        <Helmet>
            <link rel="canonical" href={`https://easytaxconsultancy.com/category-details/${categoryDetails.id}`}/>
            <meta name="description" content={categoryDetails.shortDesc}/>
            <meta name="title" content={categoryDetails.name}/>
            <title>{categoryDetails.name}</title>
        </Helmet>
        <ScrollToTop/>
      <Header />
      {categoryDetails ? (
        <CategoryDetailsBody categoryDetail={categoryDetails} />
      ) : (
        <>Loading</>
      )}
      <Footer />
    </div>
  );
}

export default CategoryDetails;
