import React from 'react';
import './ServicesDetailBody.css';
import { HashLink } from "react-router-hash-link";
import PopularServiceList from "../../CategoryDetails/Body/PopularService/PopularServiceList";

function ServicesDetailBody({ serviceDetails }) {
    return (
        <div className="sd-section">
            <div className="sd-container">
                <div className="sd-left">
                    <h2 className="sd-left-head">{serviceDetails.serviceName}</h2>
                    <p className="sd-left-paragraph">{serviceDetails.Desc}</p>
                    <HashLink to="/#contactUs">
                        <button className="sd-btn">Contact us</button>
                    </HashLink>
                </div>
                <div className="sd-right">
                    <img className="sdimg" src={serviceDetails.imageUrl} alt="sdimg" />
                </div>
            </div>

            <h2 className="sd-last-head">Popular Services</h2>
            <div className="sd-last">
                <PopularServiceList />
            </div>
        </div>
    );
}

export default ServicesDetailBody;

